"use client";

import { useRouter } from "next/navigation";
import { useEffect, type ReactNode } from "react";
import styles from "./index.module.css";

export const ProductCardLink = ({
	children,
	href,
}: {
	children: ReactNode[];
	href: string;
}) => {
	const router = useRouter();

	useEffect(() => router.prefetch(href), [router, href]);

	const navigate = (e: React.MouseEvent<HTMLDivElement>) => {
		const target = e.target as HTMLDivElement;

		if (target.closest("a") || target.closest("button")) return;

		router.push(href);
	};
	return (
		<article onClick={navigate} className={styles.root}>
			{children}
		</article>
	);
};
