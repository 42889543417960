import { env } from "@/env.mjs";
import type { DateTimeFormatOptions } from "next-intl";

export const formatDate = (dateString: string) => {
	const options: DateTimeFormatOptions = {
		day: "numeric",
		month: "long",
		year: "numeric",
	};
	const date = new Date(dateString);
	return date.toLocaleDateString("en", options);
};

export const getPublicUrl = () => {
	if (typeof window === "undefined") {
		return env.NEXT_PUBLIC_BASE_URL;
	}
	return window.location.origin;
};

/**
 *
 * @param mobile {number} - default 100
 * @param desktop {number} - default 50
 * @returns {string} - (max-width: 768px) 100vw, 50vw
 */
export const sizes = (mobile = 100, desktop = 50) => {
	return `(max-width: 768px) ${mobile}vw, ${desktop}vw`;
};
