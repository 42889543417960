import { clsx } from "@frend-digital/ui";
import { type ComponentProps } from "react";
import { buttonColors } from "../button";
import buttonStyles from "../button/index.module.css";
import styles from "./index.module.css";

export const iconButtonColors = {
	...buttonColors,
	transparent: styles.transparent,
} as const;

export const iconButtonSizes = {
	xLarge: styles.xLarge,
	large: styles.large,
	medium: styles.medium,
	small: styles.small,
};

type IconButtonSize = keyof typeof iconButtonSizes;

interface IconButtonProps extends ComponentProps<"button"> {
	color: keyof typeof iconButtonColors;
	size: IconButtonSizeProp;
}

type IconButtonSizeProp =
	| IconButtonSize
	| {
			base: IconButtonSize;
			md: IconButtonSize;
	  };

export const IconButton = ({
	children,
	color,
	size,
	className,
	...props
}: IconButtonProps) => {
	const iconButtonClasses = iconButtonColors[color];
	const iconButtonSizeClass =
		typeof size === "object"
			? clsx(iconButtonSizes[size.base], styles[`md-${size.md}`])
			: iconButtonSizes[size];

	return (
		<button
			{...props}
			className={clsx(
				className,
				styles.root,
				iconButtonClasses,
				buttonStyles.root,
				iconButtonSizeClass,
				className,
			)}>
			{children}
		</button>
	);
};
