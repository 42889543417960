import BlokWrap from "@/components/bloks/BlokWrap";
import type { AssetStoryblok, MediaStoryblok } from "@/generated/sb/sb-types";
import { getMedia, type BlokProps } from "@frend-digital/storyblok";
import { clsx } from "@frend-digital/ui";
import Image from "next/image";
import styles from "./index.module.css";

export const getAspectRatioClass = (aspect: MediaStoryblok["aspectRatio"]) => {
	switch (aspect) {
		case "landscape":
			return styles.aspectLandscape;
		case "portrait":
			return styles.aspectPortrait;
		default:
			return styles.aspectDefault;
	}
};

export type BaseMediaProps = {
	aspectRatio?: MediaStoryblok["aspectRatio"];
	sizes?: string;
};

export const MediaBlok = ({
	blok,
	aspectRatio: aspectRatioOverride,
	sizes = "100vw",
}: BlokProps<MediaStoryblok> & BaseMediaProps) => {
	const aspectRatio = getAspectRatioClass(aspectRatioOverride || blok.aspectRatio);

	return (
		<BlokWrap
			layout="page"
			editable={blok}
			as="div"
			className={clsx(styles.root, aspectRatio)}>
			{blok.source && <Asset source={blok.source} sizes={sizes} />}
		</BlokWrap>
	);
};

export const Asset = ({
	source,
	className,
	sizes,
	alt,
}: {
	source: Omit<AssetStoryblok, "id" | "name">;
	className?: string;
	sizes?: string;
	alt?: string;
}) => {
	const media = getMedia(source as AssetStoryblok);

	if (!media) return null;

	const focusPoint = parseFocusPoint(source.focus as string | undefined, {
		width: media.width,
		height: media.height,
	});

	return (
		<div
			className={clsx(className, styles.asset)}
			style={
				{
					"--default-aspect":
						media.width && media.height ? `${media.width} / ${media.height}` : "4 / 5",
				} as React.CSSProperties
			}>
			{media.type === "video" ? (
				<video src={media.url} autoPlay muted loop playsInline />
			) : (
				<Image
					style={
						{
							"--internal-object-position": focusPoint
								? `${focusPoint.xPercent}% ${focusPoint.yPercent}%`
								: undefined,
						} as React.CSSProperties
					}
					fill
					src={media.url}
					alt={alt || media.alt || ""}
					sizes={sizes}
				/>
			)}
		</div>
	);
};

function parseFocusPoint(focusPoint: string | undefined, { width = 1, height = 1 } = {}) {
	if (!focusPoint) return;

	// Focus point is in this format: <px>x<px>:<px>x<px>
	const [x = 1, y = 1] = focusPoint.split(":").at(0)?.split("x") || [];

	return {
		x,
		y,
		xPercent: Math.round((Number(x) / width) * 100),
		yPercent: Math.round((Number(y) / height) * 100),
	};
}
