"use client";

import { classed } from "@frend-digital/ui";
import {
	DialogClose,
	DialogContent,
	DialogRoot,
	DialogTrigger,
	DialogViewport,
} from "@frend-digital/ui/components";
import { ScrollAreaViewport } from "@radix-ui/react-scroll-area";
import type React from "react";
import { ScrollAreaBar, ScrollAreaRoot } from "../ScrollArea";
import styles from "./modal.module.css";

const ModalRoot = DialogRoot;

const ModalViewport = classed(DialogViewport, styles.viewport!, "modal", {
	variants: {
		position: {
			left: styles.left!,
			right: styles.right!,
		},
		backdrop: {
			false: styles["no-backdrop"]!,
		},

		layout: {
			/**
			 * Determines if the modal should be full height on mobile.
			 */
			["max-md-full"]: styles.maxMdFull!,
		},
	},
	defaultVariants: {
		position: "right",
	},
});
const ModalContent = classed(DialogContent, styles.content!);
const ModalClose = classed(DialogClose, styles.close!);
const ModalTrigger = DialogTrigger;
const ModalHeader = classed("header", styles.header!);
const ModalFooter = classed("footer", styles.footer!);
const ModalBody = ({ children }: { children: React.ReactNode }) => {
	return (
		<ScrollAreaRoot className={styles.body}>
			<ScrollAreaViewport>{children}</ScrollAreaViewport>
			<ScrollAreaBar orientation="vertical" />
		</ScrollAreaRoot>
	);
};

export {
	ModalBody,
	ModalClose,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalRoot,
	ModalTrigger,
	ModalViewport,
};
