"use client";

import {
	ModalClose,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalRoot,
	ModalTrigger,
	ModalViewport,
} from "@/components/ui/modal";

import { Close24xLarge, Filter } from "@/components/icons";
import {
	AccordionContent,
	AccordionIndicator,
	AccordionItem,
	AccordionRoot,
	AccordionTrigger,
} from "@/components/ui/Accordion";
import { AmountDot } from "@/components/ui/AmountDot";
import { Button } from "@/components/ui/button";
import { Flex } from "@/components/ui/Flex";
import { Text } from "@/components/ui/Text";
import { clsx, DialogClose } from "@frend-digital/ui";
import { useQueryParams } from "@frend-digital/ui/next";
import { isEmpty } from "@frend-digital/utils";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import {
	createContext,
	Suspense,
	use,
	useMemo,
	useOptimistic,
	useTransition,
	type PropsWithChildren,
	type TransitionStartFunction,
} from "react";
import { SORT_QUERY_KEY, sortOrders, type SortOrder } from "../../helpers";

import styles from "./index.module.css";
import type { Filters, FormattedFilter, FilterValue as IFilterValue } from "./types";

const queryParamOptions = {
	shallow: false,
	replace: true,
} as const;

type CustomFilter = FormattedFilter<React.ReactNode>;

const FilterContext = createContext<{
	startTransition: TransitionStartFunction;
}>(null!);

export const FilterModal = ({
	formattedFilters,
	totalActive,
	total,
}: {
	formattedFilters: FormattedFilter<string>[];
	totalActive: number;
	total?: number;
}) => {
	const t = useTranslations("products.filter.modal");
	const [isPending, startTransition] = useTransition();

	const close = (
		<DialogClose asChild>
			<Button size="large" isLoading={isPending} style={{ opacity: isPending ? 0.5 : 1 }}>
				{t("show")}
			</Button>
		</DialogClose>
	);

	return (
		<FilterContext.Provider value={{ startTransition }}>
			<ModalRoot>
				<ModalTrigger className={styles.modalTrigger}>
					<Filter />
					<Text className={styles.filterTrigger} bold>
						{t("trigger")}{" "}
					</Text>
					{totalActive > 0 && (
						<AmountDot variant="beige" size="xSmall">
							{totalActive}
						</AmountDot>
					)}
				</ModalTrigger>
				<ModalViewport className={styles.viewport} position="left" layout="max-md-full">
					<ModalContent className={styles.modalContent}>
						<ModalHeader className={styles.modalHeader}>
							<Flex align="center" gap="xs">
								<Text variant="h4" bold>
									{t("header")}
								</Text>
								<Suspense>
									<FilterActions filters={formattedFilters} />
								</Suspense>
							</Flex>
							<ModalClose className={styles.close}>
								<Close24xLarge />
							</ModalClose>
						</ModalHeader>
						<div className={styles.modalFilters}>
							<Suspense>
								<Filters filters={formattedFilters} />
							</Suspense>
						</div>
						<ModalFooter className={styles.modalFooter}>
							{total ? (
								<AmountDot size="large" variant="yellow" bold amount={total}>
									{close}
								</AmountDot>
							) : (
								close
							)}
						</ModalFooter>
					</ModalContent>
				</ModalViewport>
			</ModalRoot>
		</FilterContext.Provider>
	);
};

const FilterActions = ({ filters }: { filters: CustomFilter[] }) => {
	const t = useTranslations("products.filter.modal");

	const searchParams = useQueryParams(queryParamOptions);
	const { startTransition } = use(FilterContext);
	const router = useRouter();

	const selectedFilters = useMemo(() => {
		return Array.from(searchParams.entries()).filter(([key]) => {
			return filters.some((filter) => filter.field === key);
		});
	}, [searchParams, filters]);

	const clearFilters = () => {
		const newParams = new URLSearchParams(searchParams.toString());
		startTransition(() => {
			selectedFilters.forEach(([key, value]) => {
				newParams.delete(key, value);
			});

			router.replace(`${window.location.pathname}?${newParams.toString()}`);
		});
	};

	if (isEmpty(selectedFilters)) return null;

	return (
		<div className={styles.actions}>
			<AmountDot size="small" variant="beige">
				{selectedFilters.length}
			</AmountDot>
			<Text variant="body2" color="primary-dark-blue-08" asChild>
				<Button onClick={clearFilters} variant="unstyled">
					{t("clear")}
				</Button>
			</Text>
		</div>
	);
};

const FilterAccordionItem = ({ filter }: { filter: CustomFilter }) => {
	return (
		<AccordionItem open className={styles.filterAccordionItem} key={filter.field}>
			<Text asChild variant="utility2" bold>
				<AccordionTrigger className={styles.filterAccordionTrigger}>
					{filter.label}
					<AccordionIndicator />
				</AccordionTrigger>
			</Text>
			<AccordionContent
				className={clsx(styles.filterValues, styles.filterAccordionContent)}>
				{filter.values.map((value) => (
					<FilterValue field={filter.field} value={value} key={value.value} />
				))}
			</AccordionContent>
		</AccordionItem>
	);
};

const Sort = () => {
	const t = useTranslations("products.filter.modal");
	return (
		<div className={styles.filterAccordionItem}>
			<Text className={styles.filterAccordionTrigger} asChild variant="utility2">
				<h4>{t("sort")}</h4>
			</Text>
			<Suspense>
				<div className={clsx(styles.filterValues, styles.filterAccordionContent)}>
					{sortOrders.map((order) => {
						return <SortValue key={order.key} order={order} />;
					})}
				</div>
			</Suspense>
		</div>
	);
};

const SortValue = ({ order }: { order: SortOrder }) => {
	const { startTransition } = use(FilterContext);
	const t = useTranslations("sort");
	const searchParams = useQueryParams(queryParamOptions);

	const handleChange = (checked: boolean) => {
		startTransition(() => {
			if (checked) {
				searchParams.set(SORT_QUERY_KEY, order.key);
			} else {
				searchParams.delete(SORT_QUERY_KEY);
			}
		});
	};

	return (
		<ToggleButton
			value={searchParams.has(SORT_QUERY_KEY, order.key)}
			onValueChange={handleChange}>
			{t(order.key)}
		</ToggleButton>
	);
};

// eslint-disable-next-line no-import-assign
const Filters = ({ filters }: { filters: CustomFilter[] }) => {
	return (
		// <ScrollAreaRoot className={styles.filterScrollArea}>
		// 	<ScrollAreaViewport>
		<AccordionRoot className={styles.filterAccordion}>
			<Sort />
			{filters.map((filter) => (
				<FilterAccordionItem key={filter.field} filter={filter} />
			))}
		</AccordionRoot>
		// 	</ScrollAreaViewport>
		// 	<ScrollAreaBar className={styles.bar} forceMount orientation="vertical" />
		// </ScrollAreaRoot>
	);
};

const Swatch = ({ hex }: { hex: string }) => {
	return (
		<span
			className={styles.swatch}
			style={{
				backgroundColor: hex.startsWith("#") ? hex : `#${hex}`,
			}}
		/>
	);
};

export const getColor = (value: IFilterValue) => {
	if (
		"data" in value &&
		typeof value.data === "object" &&
		"hex" in value.data &&
		typeof value.data.hex === "string"
	) {
		return <Swatch hex={value.data.hex} />;
	}

	return null;
};

const FilterValue = ({ value, field }: { value: IFilterValue; field: string }) => {
	const { startTransition } = use(FilterContext);
	const searchParams = useQueryParams(queryParamOptions);

	const checked = searchParams.has(field, value.value);

	const [optimisticChecked, setOptimisticChecked] = useOptimistic(checked);

	if (value.count === 0) return null;

	return (
		<ToggleButton
			name={value.name}
			value={optimisticChecked}
			onValueChange={(checked) => {
				startTransition(() => {
					setOptimisticChecked(checked);
					if (checked) {
						searchParams.append(field, value.value);
					} else {
						searchParams.delete(field, value.value);
					}
				});
			}}>
			{getColor(value)}
			<Text variant="utility4">{value.name}</Text>
			{value.count ? <small className={styles.count}> ({value.count})</small> : null}
		</ToggleButton>
	);
};

const ToggleButton = ({
	children,
	value,
	name,
	onValueChange,
}: PropsWithChildren<{
	value: boolean;
	name?: string;
	// eslint-disable-next-line no-unused-vars
	onValueChange: (checked: boolean) => void;
}>) => {
	return (
		<label role="checkbox" aria-checked={value} className={styles.filterToggle}>
			<span className={styles.content}>{children}</span>
			<input
				className="sr-only"
				type="checkbox"
				name={name}
				checked={value}
				onChange={(e) => onValueChange(e.target.checked)}
			/>
		</label>
	);
};
