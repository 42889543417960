"use client";

import { SearchIcon } from "@/components/icons";
import { Text } from "@/components/ui/Text";
import { Button } from "@/components/ui/button";
import { ModalContent, ModalRoot, ModalViewport } from "@/components/ui/modal";
import type { SearchBarStoryblok } from "@/generated/sb/sb-types";
import { useModal } from "@/hooks/useModal";
import { getLink, type LinkReturn } from "@frend-digital/storyblok";
import Link from "next/link";
import {
	Fragment,
	Suspense,
	useEffect,
	useRef,
	type ComponentProps,
	type ReactNode,
} from "react";
import { SearchInput } from "./SearchInput";
import styles from "./index.module.css";

export type SearchProps = ComponentProps<"div"> & {
	storylink: LinkReturn;
	placeholder?: string;
	label?: string;
	suggestions?: SearchBarStoryblok["items"];
};

export const SearchModal = ({
	placeholder,
	storylink,
	label,
	suggestions,
	...props
}: SearchProps) => {
	const { isOpen, setOpen } = useModal("search");
	const inputRef = useRef<HTMLInputElement>(null!);

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => {
				inputRef?.current?.focus();
			}, 10);
		}
	}, [isOpen]);

	return (
		<ModalRoot open={isOpen} onOpenChange={setOpen}>
			<ModalViewport className={styles.viewport}>
				<ModalContent className={styles.content}>
					<section className={styles.root} {...props}>
						<SearchInput storylink={storylink} ref={inputRef} placeholder={placeholder} />
						<nav className={styles.suggestions}>
							<Text variant="utility4" bold>
								{label}
							</Text>
							{suggestions?.map((suggestion) => {
								const link = getLink(suggestion.link);

								return (
									<Fragment key={suggestion._uid}>
										<div className={styles.suggestionItems}>
											<Link
												key={suggestion._uid}
												href={link?.href || ""}
												style={{
													textDecoration: "none",
												}}>
												<Text key={suggestion._uid} variant="utility4">
													{suggestion.text}
												</Text>
											</Link>
										</div>
									</Fragment>
								);
							})}
						</nav>
					</section>
				</ModalContent>
			</ModalViewport>
		</ModalRoot>
	);
};

export const UnsuspendedSearchModalTrigger = ({ children }: { children: ReactNode }) => {
	const { setOpen } = useModal("search");

	return (
		<Button
			variant="unstyled"
			onClick={() => setOpen(true)}
			type="button"
			title="Search trigger">
			{children}
		</Button>
	);
};

export const SearchModalTrigger: typeof UnsuspendedSearchModalTrigger = (props) => {
	return (
		<Suspense fallback={<SearchIcon />}>
			<UnsuspendedSearchModalTrigger {...props} />
		</Suspense>
	);
};
