import { PAGE_SIZE } from "@/config";
import { ProductCardSkeleton } from "@/features/products/ProductCard/ProductCardSkeleton";
import { ProductGrid } from "../ProductGrid";

export const ProductCardsSkeleton = () => {
	return (
		<>
			{[...Array(PAGE_SIZE / 2)].map((_, idx) => (
				<ProductCardSkeleton key={idx} />
			))}
		</>
	);
};

export const ProductViewSkeleton = () => {
	return (
		<ProductGrid>
			<ProductCardsSkeleton />
		</ProductGrid>
	);
};
