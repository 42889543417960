import { clsx } from "@frend-digital/ui";
import styles from "./index.module.css";

interface SkeletonProps extends React.ComponentProps<"div"> {
	height?: string | number;
	width?: string | number;
	absolute?: boolean;
	fitContent?: boolean;
	as?: "div" | "span";
}

export const Skeleton = ({
	height,
	absolute,
	style,
	width,
	fitContent,
	children,
	className,
	as,
	...restProps
}: SkeletonProps) => {
	const Component = as ?? "div";
	return (
		<Component
			aria-busy="true"
			className={clsx(styles.root, className)}
			{...restProps}
			style={{
				...style,
				height,
				width: width,
				position: absolute ? "absolute" : "relative",
				...(fitContent && {
					width: "fit-content",
					height: "fit-content",
				}),
			}}>
			<span aria-hidden="true">{children}</span>
		</Component>
	);
};
