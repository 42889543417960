export const PATHS = {
	Checkout: {
		Root: "/checkout",
		Shipping: "/checkout/shipping",
		Payment: "/checkout/payment",
	},
	Products: {
		Root: "/products",
		byLocale: (locale: string, path?: string) =>
			`/${locale === "no" ? "produkter" : "products"}/${path ?? ""}`,
	},
	Product: (uri: string) => `/product/${uri}`,
	Account: {
		Root: "/account",
		Login: "/account/login",
		Register: "/account/register",
		Forgot: "/account/forgot-password",
	},
	Explore: {
		Root: "/explore",
	},
	Home: {
		Root: "/",
	},
} as const;

export const PAGE_SIZE = 16;

export const BASE_URL = "https://scandinavianexplorer.no/";
export const SITE_NAME = "Scandinavian explorer";
