"use client";

import { clsx } from "@frend-digital/ui";
import {
	unstable_useEffectEvent,
	useScroll,
	type ScrollInfo,
} from "@frend-digital/ui/hooks";
// import { useSticky } from "move-to-front/hooks/useSticky";
import useSticky from "move-to-front/hooks/useSticky";
import { useRef, useState, type ReactNode } from "react";
import { ACTIVE_FILTERS_CONTAINER_ID } from "./config";
import styles from "./index.module.css";

interface FilterBarContainerProps {
	children: ReactNode;
}

export const FilterBarContainer = ({ children }: FilterBarContainerProps) => {
	const [topOffset, setTopOffset] = useState<string>("var(--filterbar-top-position)");
	const scrollYRef = useRef(0);

	const onScroll = unstable_useEffectEvent((scroll: ScrollInfo) => {
		const direction = scroll.y > scrollYRef.current ? "down" : "up";

		if (direction === "up") {
			setTopOffset("var(--filterbar-top-position)");
		} else {
			setTopOffset("15px");
		}

		scrollYRef.current = scroll.y;
	});

	useScroll(onScroll);

	const [ref, sticky] = useSticky();

	return (
		<>
			<section
				ref={ref}
				className={clsx(styles.filterBarContainer, sticky && styles.active)}
				style={{ top: topOffset }}>
				{children}
			</section>
			<div
				style={{ position: "relative", zIndex: 40 }}
				id={ACTIVE_FILTERS_CONTAINER_ID}
			/>
		</>
	);
};
