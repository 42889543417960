import { Slot } from "@frend-digital/ui";
import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";
import { type ComponentProps, type PropsWithChildren } from "react";
import type { ISbComponentType } from "storyblok-js-client";

export interface BlokWrapProps extends ComponentProps<"div"> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	editable: ISbComponentType<any>;
	layout?: "page" | "full" | "section" | "content";
	as?: "div" | "section" | "article" | "main";
	asChild?: boolean;

	dataBlok?: boolean;
}

const BlokWrap = ({
	children,
	editable,
	layout,
	as: Component = "section",
	asChild,
	dataBlok = true,
	...rest
}: PropsWithChildren<BlokWrapProps>) => {
	const ComponentToUse = asChild ? Slot : Component;
	const layoutProp = layout
		? {
				"data-layout": layout,
			}
		: {};
	const props = {
		...rest,
		"data-blok": dataBlok ? true : undefined,
		"data-blok-name":
			process.env.NODE_ENV === "development" ? editable.component : undefined,
	};
	return (
		<ComponentToUse
			{...layoutProp}
			{...storyblokEditable(editable as SbBlokData)}
			{...props}>
			{children}
		</ComponentToUse>
	);
};

export default BlokWrap;
