import { classed, clsx } from "@frend-digital/ui";
import type React from "react";
import type { ComponentProps } from "react";
import styles from "./index.module.css";

export const AmountDotBase = classed.div({
	base: styles.root!,
	variants: {
		variant: {
			blue: styles.blue!,
			beige: styles.beige!,
			yellow: styles.yellow!,
			white: styles.white!,
			inactive: styles.inactive!,
		},
		size: {
			xSmall: styles.xSmall!,
			small: styles.small!,
			medium: styles.medium!,
			large: styles.large!,
			notification: styles.notification!,
		},
		bold: {
			true: styles.bold!,
		},
	},
	defaultVariants: {
		variant: "blue",
		size: "medium",
	},
});

type AmountDotBaseProps = Omit<ComponentProps<typeof AmountDotBase>, "children">;

type AmountDotProps = AmountDotBaseProps &
	(
		| {
				children?: React.ReactElement;
				amount: number | string | React.ReactNode;
		  }
		| { children: string | number; amount?: never }
	);

export const AmountDot = ({
	className,
	children,
	amount,
	variant,
	size,
	bold,
	...rest
}: AmountDotProps) => {
	if (typeof children === "number" || typeof children === "string") {
		return (
			<AmountDotBase {...{ variant, size, bold, className }} {...rest}>
				{children}
			</AmountDotBase>
		);
	}

	return (
		<div {...rest} className={clsx(styles.dotContainer, className)}>
			{children}
			<AmountDotBase {...{ variant, size, bold }}>{amount}</AmountDotBase>
		</div>
	);
};

export const StaticAmountDot = ({
	className,
	children,
	amount,
	variant,
	size,
	bold,
	...rest
}: AmountDotProps) => {
	return (
		<AmountDotBase className={className} {...{ variant, size, bold }}>
			{amount}
		</AmountDotBase>
	);
};
