"use client";

import type { FormattedMedia } from "@/lib/centra/formatters/media";
import { createContext, useCallback, useContext, useState, type ReactNode } from "react";

type ProductImageContextType = {
	selectedImage: FormattedMedia | null;
	setSelectedImage: (image: FormattedMedia | null) => void;
};

const ProductImageContext = createContext<ProductImageContextType | undefined>(undefined);

export const ProductImageProvider = ({ children }: { children: ReactNode }) => {
	const [selectedImage, setSelectedImage] = useState<FormattedMedia | null>(null);

	const handleSetImage = useCallback((image: FormattedMedia | null) => {
		setSelectedImage(image);
	}, []);

	return (
		<ProductImageContext.Provider
			value={{ selectedImage, setSelectedImage: handleSetImage }}>
			{children}
		</ProductImageContext.Provider>
	);
};

export const useProductImage = () => {
	const context = useContext(ProductImageContext);
	if (!context) {
		throw new Error("useProductImage must be used within a ProductImageProvider");
	}
	return context;
};
