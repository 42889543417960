"use client";

import { TabBar } from "@/components/ui/TabBar";
import { useTranslations } from "next-intl";
import { Suspense } from "react";
import { FilterModalWrapper } from "./FilterModalWrapper";
import { useDisplayType } from "./hooks";
import styles from "./index.module.css";
import { useProductLayout } from "./store";
import type { FilterLabels, Filters } from "./types";

const UnsuspendedFilterBar = ({
	filters,
	labels,
	total,
}: {
	filters: Filters | false;
	labels: FilterLabels;
	total?: number;
}) => {
	const t = useTranslations("products.filter");
	const { layout, setLayout } = useProductLayout();

	const { display, setDisplayType } = useDisplayType();

	return (
		<div className={styles.filterBar}>
			{filters ? (
				<FilterModalWrapper total={total} filters={filters} labels={labels} />
			) : (
				<span></span>
			)}
			<div className={styles.center}>
				<TabBar
					value={layout}
					onValueChange={(value) => setLayout(value as typeof layout)}
					tabs={[
						{ name: `4 ${t("images")}`, value: "four" },
						{ name: `3 ${t("images")}`, value: "three" },
					]}
				/>
			</div>
			<div>
				<TabBar
					value={display ?? "model"}
					onValueChange={(value) => {
						setDisplayType(value);
					}}
					tabs={[
						{ name: t("model"), value: "model" },
						{ name: t("product"), value: "product" },
					]}
				/>
			</div>
		</div>
	);
};

export const FilterBar: typeof UnsuspendedFilterBar = (props) => {
	return (
		<Suspense>
			<UnsuspendedFilterBar {...props} />
		</Suspense>
	);
};
