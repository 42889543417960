"use client";

import type { ProductCardVariants } from "@/lib/centra/formatters";
import type { FormattedMedia } from "@/lib/centra/formatters/media";
import { clsx } from "@frend-digital/ui";
import Image from "next/image";
import Link from "next/link";
import { useCallback } from "react";
import { useProductImage } from "./ProductDisplayProvider";
import styles from "./index.module.css";

const sizes = {
	small: styles.small,
	large: styles.large,
} as const;

type Sizes = keyof typeof sizes;

interface ProductVariantProps {
	image: FormattedMedia;
}

export const ProductVariantImage = ({ image }: ProductVariantProps) => {
	const { setSelectedImage } = useProductImage();

	const handleMouseEnter = useCallback(() => {
		setSelectedImage(image);
	}, [image, setSelectedImage]);

	return (
		<Image
			onMouseEnter={handleMouseEnter}
			src={image.href}
			height={60}
			width={48}
			alt={image.alt || ""}
		/>
	);
};

export const ProductVariant = ({
	variant,
	selected,
	size = "small",
}: {
	variant: NonNullable<ProductCardVariants>[number];
	selected?: boolean;
	size?: Sizes;
}) => {
	const sizeClass = sizes[size];
	const image =
		variant.media.find((img) => img.img_attributes_isPackshot === "1") ||
		variant.media.at(0)!;

	if (!image?.href) return null;

	return (
		<Link
			key={variant.id}
			className={clsx(styles.variantImg, selected && styles.borderBlack, sizeClass)}
			href={`/product/${variant.uri!}`}
			prefetch={true}
			title={variant.variantName}>
			<Image src={image?.href} height={200} width={161} alt={image?.alt || ""} />
		</Link>
	);
};
