"use client";

import { clsx } from "@frend-digital/ui";
import { useControllableState } from "@frend-digital/ui/hooks";
import { Text } from "../Text";
import styles from "./index.module.css";

export interface Tab<T> {
	name: React.ReactNode;
	value: T;
}

interface Props<T> {
	tabs: Tab<T>[];
	children?: React.ReactNode;
	value?: T;
	// eslint-disable-next-line no-unused-vars
	onValueChange?: (value: T) => void;
	className?: string;
	size?: string;
}

export const TabBar = <T = string,>({
	tabs,
	value,
	onValueChange,
	className,
	size,
}: Props<T>) => {
	const [state, setState] = useControllableState(
		undefined,
		value,
		onValueChange as React.Dispatch<React.SetStateAction<T>>,
	);

	return (
		<div
			role="tablist"
			className={clsx(styles.root, className, size === "large" && styles.large)}>
			{tabs.map((tab, index) => {
				const active = tab.value === state;
				return (
					<button
						role="tab"
						key={index}
						className={clsx(styles.tab, active && styles.active)}
						onClick={() => setState(tab.value)}>
						<Text className={styles.tabName} asChild={typeof tab.name === "object"}>
							{tab.name}
						</Text>
					</button>
				);
			})}
		</div>
	);
};
