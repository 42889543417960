"use client";

import { Chevron } from "@/components/icons";
import { clsx } from "@frend-digital/ui";
import { range } from "move-to-front/utils/range";
import Link from "next/link";
import { Fragment, type ComponentProps } from "react";
import { Skeleton } from "../Skeleton";
import { Text } from "../Text";
import styles from "./index.module.css";

type BreadcrumbBase = {
	title: string;
};

type BreadcrumbWithHref = BreadcrumbBase & {
	href: string;
	onClick?: never;
};

type BreadcrumbWithOnClick = BreadcrumbBase & {
	onClick: () => void;
	href?: never;
};

type Breadcrumb = BreadcrumbWithHref | BreadcrumbWithOnClick;

type BreadcrumbProps = ComponentProps<"nav"> & {
	breadcrumbs: Breadcrumb[];
};

export const Breadcrumbs = ({ breadcrumbs, className, ...props }: BreadcrumbProps) => {
	return (
		<nav aria-label="breadcrumb" className={clsx(styles.root, className)} {...props}>
			{breadcrumbs.map((breadcrumb, i) => {
				return (
					<Fragment key={i}>
						<Chevron
							style={{
								rotate: "270deg",
								display: i === 0 ? "none" : "block",
							}}
							width={15}
							height={15}
						/>
						{breadcrumb.href ? (
							<Link href={breadcrumb.href} title={breadcrumb.title}>
								<Text
									className={clsx(
										styles.breadcrumb,
										(i !== breadcrumbs.length - 1 || breadcrumbs.length === 1) &&
											styles.dark,
									)}
									variant="utility5">
									{breadcrumb.title}
								</Text>
							</Link>
						) : (
							<button onClick={breadcrumb.onClick}>
								<Text
									className={clsx(
										styles.breadcrumb,
										i !== breadcrumbs.length - 1 && styles.dark,
									)}
									variant="utility5">
									{breadcrumb.title}
								</Text>
							</button>
						)}
					</Fragment>
				);
			})}
		</nav>
	);
};

export const BreadcrumbSkeleton = ({
	count = 2,
	...props
}: ComponentProps<"nav"> & { count?: number }) => {
	return (
		<nav aria-hidden aria-label="breadcrumb" className={styles.root} {...props}>
			{range(count).map((val, i) => (
				<Fragment key={val}>
					<Skeleton fitContent>
						<Text className={clsx(styles.breadcrumb)} variant="utility5">
							linking
						</Text>
					</Skeleton>
					<span style={{ width: 10, height: 1 }}></span>
				</Fragment>
			))}
		</nav>
	);
};
