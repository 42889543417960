"use client";

import { Input } from "@/components/ui/Input";
import type { LinkReturn } from "@frend-digital/storyblok";
import { useTranslations } from "next-intl";
import Form from "next/form";
import { useFormStatus } from "react-dom";

type SearchInputProps = React.ComponentProps<"input"> & {
	storylink?: LinkReturn;
};

export const SearchInput = ({ storylink, ...props }: SearchInputProps) => {
	if (!storylink) return null;

	return (
		<Form action={storylink.href}>
			<FormInput {...props} />
		</Form>
	);
};

export const FormInput = ({ placeholder, ...props }: SearchInputProps) => {
	const t = useTranslations("search");
	const { pending } = useFormStatus();
	return (
		<Input
			placeholder={pending ? t("searching") : placeholder}
			variant="search"
			name="q"
			{...props}
		/>
	);
};
