"use client";

import { Tag } from "@/components/ui/Tag";
import { useGlobalConfigAsync } from "@/features/GlobalContext";
import type { SaleStoryblok } from "@/generated/sb/sb-types";
import { useTranslations } from "next-intl";
import { Suspense, type ComponentProps } from "react";

interface Tag {
	color: ComponentProps<typeof Tag>["variant"];
	text: string;
}

interface Props {
	tags: Tag[];
	className?: string;
	onSale?: boolean;
	discountPercent?: number;
	size?: ComponentProps<typeof Tag>["size"];
}

const getColor = (color: string) => {
	if (color.includes("#")) {
		return color;
	}

	return `#${color}`;
};

type SalesTagProps = Omit<Props, "tags">;

type ColorPicker = {
	color?: string;
};

const UnsuspendedSalesTag = ({
	onSale,
	discountPercent,
	className,
	...rest
}: SalesTagProps) => {
	const globalConfig = useGlobalConfigAsync();
	const t = useTranslations("tag");
	const salesTags = globalConfig.saleTags;

	const salesTag = salesTags?.find(
		(tag) => String(tag.discountPercent) === String(discountPercent) && onSale,
	);

	if (!onSale) return null;

	const salesTagToUse = (salesTag ?? {
		_uid: "",
		component: "sale",
		discountPercent: "0",
		title: t("sale"),
		color: {
			color: undefined,
		},
		textColor: {
			color: undefined,
		},
	}) as SaleStoryblok & {
		color: ColorPicker;
		textColor: ColorPicker;
	};

	return (
		<Tag
			variant="red"
			style={{
				backgroundColor: salesTagToUse.color?.color
					? getColor(salesTagToUse.color.color)
					: undefined,
				color: salesTagToUse.textColor?.color
					? getColor(salesTagToUse.textColor.color)
					: undefined,
			}}
			className={className}
			size="small"
			weight="medium"
			{...rest}>
			{salesTagToUse.title}
		</Tag>
	);
};

export const SalesTag = (props: SalesTagProps) => {
	return (
		<Suspense>
			<UnsuspendedSalesTag {...props} />
		</Suspense>
	);
};

const UnsuspendedProductCardTags = ({
	tags,
	className,
	onSale,
	discountPercent,
	...rest
}: Props) => {
	return (
		<>
			<SalesTag
				onSale={onSale}
				discountPercent={discountPercent}
				className={className}
				{...rest}
			/>
			{tags.map((tag, i) => (
				<Tag className={className} size="small" key={i} variant={tag.color} {...rest}>
					{tag.text}
				</Tag>
			))}
		</>
	);
};

export const ProductCardTags = (props: Props) => {
	return (
		<Suspense>
			<UnsuspendedProductCardTags {...props} />
		</Suspense>
	);
};
