"use client";

import type { GlobalConfigStoryblok } from "@/generated/sb/sb-types";
import { createContext, use, useContext } from "react";

interface GlobalContext {
	configPromise: Promise<GlobalConfigStoryblok>;
}

export const GlobalContext = createContext<GlobalContext>(null!);

export const GlobalConfigProvider = ({
	children,
	configPromise,
}: { children: React.ReactNode } & Pick<GlobalContext, "configPromise">) => {
	return (
		<GlobalContext.Provider value={{ configPromise }}>{children}</GlobalContext.Provider>
	);
};

export const useGlobalConfigAsync = () => {
	const ctx = useContext(GlobalContext);

	if (!ctx) {
		throw new Error("useGlobalConfigAsync must be used within a GlobalConfigProvider");
	}

	return use(ctx.configPromise);
};
