"use client";

import { AmountDot } from "@/components/ui/AmountDot";
import { Button } from "@/components/ui/button";
import { PAGE_SIZE } from "@/config";
import { useIntersectionObserverAPI } from "@frend-digital/ui/hooks";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import { useState } from "react";
import type {
	FetchProductsFn,
	FetchProductsOptions,
	FetchProductsReturn,
} from "../../actions";
import { ProductGrid } from "../ProductGrid";
import styles from "./index.module.css";
import { ProductCardsSkeleton } from "./ProductViewSkeleton";

export const Sentinel = ({ action }: { action: () => void }) => {
	const obserrver = useIntersectionObserverAPI(([entry]) => {
		if (entry?.isIntersecting) {
			action();
		}
	});

	return (
		<span
			ref={(element) => {
				obserrver?.observe(element!);
				return () => {
					obserrver?.disconnect();
				};
			}}
		/>
	);
};

export const InfiniteProductView = ({
	initialData,
	action,
	options,
	cache,
}: {
	initialData: FetchProductsReturn;
	action: FetchProductsFn;
	options: FetchProductsOptions;
	cache: boolean;
}) => {
	const t = useTranslations("products");
	const [isAutoLoadEnabled, setIsAutoLoadEnabled] = useState<boolean>(false);

	const { data, isLoading, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery({
		queryKey: ["fetchProducts", options, cache],
		queryFn: async ({ pageParam }) => {
			const data = await action({ ...options, offset: pageParam * PAGE_SIZE }, cache);
			return data;
		},
		initialData: {
			pageParams: [0],
			pages: [initialData],
		},
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages) =>
			lastPage.total! > PAGE_SIZE * allPages.length ? allPages.length : undefined,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const initialProductCount = (data.pages[0]?.component as unknown as any[]).length;
	const totalProductsCount = data.pages[0]?.total;
	const isLoadMoreAvailable = totalProductsCount !== initialProductCount;

	const handleClick = () => {
		setIsAutoLoadEnabled(true);
		fetchNextPage();
	};

	return (
		<>
			<ProductGrid>
				{data?.pages.map((page) => page.component)}
				{isFetching && <ProductCardsSkeleton />}
			</ProductGrid>

			{isAutoLoadEnabled && hasNextPage ? (
				<Sentinel action={fetchNextPage} />
			) : isLoadMoreAvailable && !isAutoLoadEnabled ? (
				<div className={styles.showAll}>
					<Button
						size={{
							base: "medium",
							md: "xLarge",
						}}
						color="white"
						onClick={handleClick}
						disabled={isLoading}>
						{t("loadMore")}
						<AmountDot variant="beige" size={"small"}>
							{totalProductsCount
								? totalProductsCount - initialProductCount
								: initialProductCount}
						</AmountDot>
					</Button>
				</div>
			) : null}
		</>
	);
};
