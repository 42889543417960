"use client";
import { clsx } from "@frend-digital/ui";
import { type PropsWithChildren } from "react";
import { useProductLayout } from "../Filters/store";
import styles from "./index.module.css";

export const ProductGrid = ({ children }: PropsWithChildren) => {
	const layout = useProductLayout((s) => s.layout);
	return <section className={clsx(styles.list, styles[layout])}>{children}</section>;
};
