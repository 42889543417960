import { classed } from "@frend-digital/ui";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import type { ComponentProps } from "react";
import styles from "./index.module.css";

export const ScrollAreaRoot = classed(ScrollAreaPrimitive.Root, styles.ScrollAreaRoot);

export const ScrollAreaViewport = classed(
	ScrollAreaPrimitive.Viewport,
	styles.ScrollAreaViewport,
);

export const ScrollAreaTrack = classed(
	ScrollAreaPrimitive.Scrollbar,
	styles.ScrollAreaScrollbar,
);

export const ScrollAreaThumb = classed(ScrollAreaPrimitive.Thumb, styles.ScrollAreaThumb);

export const ScrollAreaCorner = classed(
	ScrollAreaPrimitive.Corner,
	styles.ScrollAreaCorner,
);

export const ScrollAreaBar = (props: ComponentProps<typeof ScrollAreaTrack>) => (
	<ScrollAreaTrack {...props}>
		<ScrollAreaThumb />
	</ScrollAreaTrack>
);
