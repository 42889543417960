import { useEffect, useRef, useState } from "react";

function useSticky() {
	const [isSticky, setIsSticky] = useState(false);
	const stickyRef = useRef<HTMLElement>(null);
	const sentinelRef = useRef<HTMLElement | null>(null);

	useEffect(() => {
		if (typeof window === "undefined" || !stickyRef.current) return;

		const stickyElement = stickyRef.current;

		// Create and insert the sentinel element
		const sentinel = document.createElement("div");
		sentinel.style.position = "absolute";
		sentinel.style.height = "0px";
		sentinel.style.width = "0px";
		sentinel.style.opacity = "0";
		stickyElement.parentElement?.insertBefore(sentinel, stickyElement);
		sentinelRef.current = sentinel;

		let rafId: number | null = null;

		const checkSticky = () => {
			const stickyRect = stickyElement.getBoundingClientRect();
			const sentinelRect = sentinel.getBoundingClientRect();

			const isSticky = sentinelRect.top < stickyRect.top;
			setIsSticky(isSticky);

			rafId = null;
		};

		const onScroll = throttle(() => {
			if (rafId === null) {
				rafId = requestAnimationFrame(checkSticky);
			}
		});

		const onResize = () => {
			checkSticky();
		};

		window.addEventListener("scroll", onScroll);
		window.addEventListener("resize", onResize);

		checkSticky(); // Initial check

		return () => {
			window.removeEventListener("scroll", onScroll);
			window.removeEventListener("resize", onResize);
			if (rafId !== null) {
				cancelAnimationFrame(rafId);
			}
			sentinel.remove();
		};
	}, []);

	return [stickyRef, isSticky] as const;
}

export default useSticky;

const throttle = <T extends Function>(fn: T, wait: number = 16): T => {
	let inThrottle: boolean, lastFn: ReturnType<typeof setTimeout>, lastTime: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function (this: any) {
		const context = this,
			args = arguments;
		if (!inThrottle) {
			fn.apply(context, args);
			lastTime = Date.now();
			inThrottle = true;
		} else {
			clearTimeout(lastFn);
			lastFn = setTimeout(
				() => {
					if (Date.now() - lastTime >= wait) {
						fn.apply(context, args);
						lastTime = Date.now();
					}
				},
				Math.max(wait - (Date.now() - lastTime), 0),
			);
		}
	} as unknown as T;
};
