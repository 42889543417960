import { useQueryParams } from "@frend-digital/ui/next";
import { useMemo } from "react";
import type {
	CentraFilterValue,
	Filters,
	FilterValue,
	FilterValueName,
	FormattedFilter,
} from "./types";

export type GetNameFn = (
	data: FilterValueName | undefined | null,
	value?: CentraFilterValue,
) => string | null;

export type GetLabelFn<Label> = (fieldName: string) => Label | null;

export const useFilters = <Label = string>(
	filters: Filters,
	options: {
		// eslint-disable-next-line no-unused-vars
		getLabel: GetLabelFn<Label>;
		// eslint-disable-next-line no-unused-vars
		getName: GetNameFn;
	},
) => {
	const formattedFilters = useMemo(() => {
		const filterValues: FormattedFilter<Label>[] = [];

		for (const filter of filters) {
			if (!filter.field || !filter.values?.length) continue;

			const label = options.getLabel(filter.field);

			if (!label) continue;

			const values = filter.values.reduce((acc, value) => {
				if (!value.value) {
					return acc;
				}
				if (!value.data) {
					return acc;
				}

				let nameData: string | string[] | null = null;
				if (typeof value.data === "string") {
					nameData = value.data;
				}

				if (typeof value.data === "object") {
					if (Array.isArray(value.data?.name)) {
						nameData = value.data.name;
					}

					if (typeof value.data.name === "string") {
						nameData = value.data.name;
					}
				}

				const name = options.getName(nameData, value);

				if (!name) return acc;

				const filterValue: FilterValue = {
					...value,
					name,
					value: value.value,
				};

				return [...acc, filterValue];
			}, [] as FilterValue[]);

			filterValues.push({
				field: filter.field,
				values,
				label: label,
			});
		}

		return filterValues;
	}, [filters, options]);

	return formattedFilters;
};

export const useDisplayType = () => {
	const searchParams = useQueryParams();

	const display = searchParams.get("display");

	const setDisplayType = (value: string) => {
		if (value !== "model" && value !== "product") return;
		searchParams.set("display", value);
	};

	return { display, setDisplayType };
};
