import { useQueryParams } from "@frend-digital/ui/next";
import { useCallback, useEffect, useMemo } from "react";

type ModalKeys =
	| "cart"
	| "search"
	| "shipping"
	| "material"
	| "fit"
	| "size"
	| "wishlist";

export const useModal = (modalKey: ModalKeys) => {
	const searchParams = useQueryParams();

	const isOpen = useMemo(
		() => searchParams.has("modal", modalKey),
		[searchParams, modalKey],
	);

	const open = useCallback(() => {
		searchParams.set("modal", modalKey);
	}, [searchParams, modalKey]);

	const close = useCallback(() => {
		searchParams.delete("modal", modalKey);
	}, [searchParams, modalKey]);

	const setOpen = useCallback(
		(openState: boolean) => {
			if (openState) {
				open();
			} else {
				close();
			}
		},
		[open, close],
	);

	useEffect(() => {
		const handleResize = () => {
			if (isOpen) {
				close();
			}
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isOpen, close]);

	return {
		isOpen,
		open,
		close,
		setOpen,
	};
};
