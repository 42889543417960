"use client";

import { useDisplayType } from "@/features/products/product-list/components/Filters/hooks";
import { sizes } from "@/lib/utils";
import Image from "next/image";
import type { FormattedMedia } from "node_modules/@frend-digital/centra/src/formatting/atomics/baseAtomics";
import { Fragment, Suspense } from "react";
import { useProductImage } from "./ProductDisplayProvider";

const FALLBACK_IMAGE = "/no-product.jpg";

export const UnsuspendedProductCardImage = ({ media }: { media: FormattedMedia[] }) => {
	const { display } = useDisplayType();
	const { selectedImage } = useProductImage();

	let image = null;

	if (!media || media.length === 0 || !media.at(0)) {
		image = {
			href: FALLBACK_IMAGE,
			alt: "No image found",
		};
	} else {
		image =
			display === "product"
				? media.find((img) => img.img_attributes_isPackshot === "1") || media.at(0)!
				: media.at(0)!;
	}

	const imageToDisplay = selectedImage ?? image;

	return (
		<div style={{ width: "100%", aspectRatio: "4 / 5", position: "relative" }}>
			<Image
				fill
				src={imageToDisplay.href}
				style={{ objectFit: "cover" }}
				sizes={sizes(100, 50)}
				alt={image.alt}
			/>
		</div>
	);
};

export const ProductCardImage: typeof UnsuspendedProductCardImage = (props) => {
	const media = props.media.at(0);

	return (
		<Fragment>
			<Suspense
				fallback={
					<div style={{ width: "100%", aspectRatio: "4 / 5", position: "relative" }}>
						<Image
							src={media?.href ?? FALLBACK_IMAGE}
							fill
							style={{ objectFit: "cover" }}
							sizes={sizes(100, 50)}
							alt={media?.alt ?? "No image found"}
						/>
					</div>
				}>
				<UnsuspendedProductCardImage {...props} />
			</Suspense>
		</Fragment>
	);
};
