"use client";

import { Button } from "@/components/ui/button";
import { useQueryParams } from "@frend-digital/ui/next";
import { useTranslations } from "next-intl";
import { useCallback, type MutableRefObject } from "react";

import { Close } from "@/components/icons";
import { Text } from "@/components/ui/Text";
import { useHydrated } from "@frend-digital/ui/hooks";
import { Portal } from "@radix-ui/react-portal";
import { Translate } from "move-to-front/ui/Translate";
import { ACTIVE_FILTERS_CONTAINER_ID } from "./config";
import { FilterModal } from "./FilterModal";
import { useFilters, type GetNameFn } from "./hooks";
import styles from "./index.module.css";
import type { FilterLabels, Filters } from "./types";

interface Props {
	filters: Filters;
	labels: FilterLabels;
	total?: number;
	containerRef?: MutableRefObject<HTMLDivElement>;
}

export const FilterModalWrapper = (props: Props) => {
	const t = useTranslations("products.filter");

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getName = useCallback(
		((name, value) => {
			if (Array.isArray(name)) {
				return name.at(-1) ?? t("missing");
			}

			if (name) {
				return name;
			}

			if (value && typeof value.data === "object") {
				if ("title" in value.data && typeof value.data.title === "string") {
					return value.data.title;
				}
			}

			return null;
		}) satisfies GetNameFn,
		[t],
	);

	const getLabel = useCallback(
		(fieldName: string) => {
			if (!props.labels) return null;

			if (props.labels[fieldName]) {
				return props.labels[fieldName];
			}

			return null;
		},
		[props.labels],
	);

	const searchParams = useQueryParams({
		shallow: false,
	});

	const hydrated = useHydrated();

	const formattedFilters = useFilters(props.filters, {
		getLabel,
		getName,
	});

	const activeFilters = formattedFilters
		.map((filter) => {
			const matchedFilters = searchParams.getAll(filter.field);

			if (matchedFilters.length > 0) {
				return filter.values
					.filter((f) => matchedFilters.includes(f.value))
					.map((f) => ({
						queryParam: filter.field,
						name: f.name,
						value: f.value,
					}));
			}
		})
		.flat()
		.filter(Boolean);

	const activeSizeFilter = searchParams.get("sort");

	return (
		<>
			<FilterModal
				total={props.total}
				totalActive={activeFilters.length}
				formattedFilters={formattedFilters}
			/>
			{hydrated && (
				<Portal container={document.getElementById(ACTIVE_FILTERS_CONTAINER_ID)}>
					{activeFilters.length > 0 && (
						<section className={styles.activeFilters}>
							{activeFilters.map((f, i) => (
								<Button
									className={styles.activeFilter}
									variant="unstyled"
									onClick={() => {
										searchParams.delete(f.queryParam, f.value);
									}}
									key={i}>
									<Text bold className={styles.filterText}>
										{f?.name}
									</Text>

									<Close />
								</Button>
							))}
							{activeSizeFilter && (
								<Button
									className={styles.activeFilter}
									variant="unstyled"
									onClick={() => {
										searchParams.delete("sort");
									}}>
									<Text bold className={styles.filterText}>
										<Translate value={`sort.${activeSizeFilter}`} /> x
									</Text>
								</Button>
							)}
							{activeFilters.length > 0 && (
								<Button
									onClick={() => {
										if (searchParams.has("sort")) {
											searchParams.delete("sort");
										}
										for (const filter of activeFilters) {
											searchParams.delete(filter.queryParam);
										}
									}}
									variant="unstyled">
									<Text className={styles.filterText} color="primary-dark-blue-06">
										{t("clear")}
									</Text>
								</Button>
							)}
						</section>
					)}
				</Portal>
			)}
		</>
	);
};
