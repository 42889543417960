"use client";

import { Chevron } from "@/components/icons";
import { classed } from "@frend-digital/ui";
import { AccordionPrimitive } from "@frend-digital/ui/components";
import styles from "./index.module.css";

export const AccordionRoot = classed(AccordionPrimitive.Root, styles.accordionRoot);

export const AccordionItem = classed(AccordionPrimitive.Item, styles.accordionItem);

export const AccordionTrigger = classed(
	AccordionPrimitive.Trigger,
	styles.accordionTrigger,
);

export const AccordionContent = classed(
	AccordionPrimitive.Content,
	styles.accordionContent,
);

export const AccordionIndicator = classed(Chevron, styles.chevron);
