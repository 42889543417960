import { create } from "zustand";

interface ProductLayoutStore {
	layout: "four" | "three";
	displayType: "model" | "product";

	// eslint-disable-next-line no-unused-vars
	setLayout: (layout: "four" | "three") => void;
	// eslint-disable-next-line no-unused-vars
	setDisplayType: (displayType: "model" | "product") => void;
}

export const useProductLayout = create<ProductLayoutStore>((set) => ({
	layout: "four",
	displayType: "model",
	setLayout: (layout) => set({ layout }),
	setDisplayType: (displayType) => set({ displayType }),
}));
