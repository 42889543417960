import type { paths } from "@frend-digital/centra-types/oas";
import { isEmpty } from "@frend-digital/utils";
import type { OASBody } from "node_modules/@frend-digital/centra/src/server/types";
import type { FetchProductsOptions } from "./actions";

type NextSearchParams = Record<string, string | string[] | undefined>;

type CentraSortOrderType = NonNullable<OASBody<paths["/products"]>["sortOrder"]>[number];

export type SortField = CentraSortOrderType["field"];

type Order = NonNullable<CentraSortOrderType["order"]>;

export type SortKey = `${SortField}_${Order}`;

interface DefinedSortType {
	field: SortField;
	order: Order;
	key: SortKey;
}

type SortOrders = Record<
	`${SortField}_${NonNullable<CentraSortOrderType["order"]>}`,
	DefinedSortType
>;

export const availableSortOrders = {
	categoryItemSort_asc: {
		field: "categoryItemSort",
		order: "asc",
		key: "categoryItemSort_asc",
	},
	categoryItemSort_desc: {
		field: "categoryItemSort",
		order: "desc",
		key: "categoryItemSort_desc",
	},
	collectionUri_asc: {
		field: "collectionUri",
		order: "asc",
		key: "collectionUri_asc",
	},
	collectionUri_desc: {
		field: "collectionUri",
		order: "desc",
		key: "collectionUri_desc",
	},
	createdAt_asc: {
		field: "createdAt",
		order: "asc",
		key: "createdAt_asc",
	},
	createdAt_desc: {
		field: "createdAt",
		order: "desc",
		key: "createdAt_desc",
	},
	modifiedAt_asc: {
		field: "modifiedAt",
		order: "asc",
		key: "modifiedAt_asc",
	},
	modifiedAt_desc: {
		field: "modifiedAt",
		order: "desc",
		key: "modifiedAt_desc",
	},
	priceAsNumber_asc: {
		field: "priceAsNumber",
		order: "asc",
		key: "priceAsNumber_asc",
	},
	priceAsNumber_desc: {
		field: "priceAsNumber",
		order: "desc",
		key: "priceAsNumber_desc",
	},
	uri_asc: {
		field: "uri",
		order: "asc",
		key: "uri_asc",
	},
	uri_desc: {
		field: "uri",
		order: "desc",
		key: "uri_desc",
	},
} satisfies SortOrders;

export function getSortOrders<T extends SortKey[]>(keys: T) {
	return keys.map((key) => availableSortOrders[key]);
}

export function getSortOrderFromParam(
	key?: string | string[],
): CentraSortOrderType | undefined {
	if (typeof key !== "string") return;
	const order = availableSortOrders[key as SortKey];
	return order
		? {
				field: order.field,
				order: order.order,
			}
		: undefined;
}

export const sortOrders = getSortOrders([
	"priceAsNumber_asc",
	"priceAsNumber_desc",
	"createdAt_desc",
]);

export type SortOrder = (typeof sortOrders)[number];

export const SORT_QUERY_KEY = "sort";

export function getFiltersFromParams(
	searchParams: NextSearchParams,
	filterKeys: string[] | undefined,
) {
	if (!filterKeys) return;

	const filterValues = new Map<string, string | string[]>();

	for (const key of filterKeys) {
		const paramValue = searchParams[key];

		if (!paramValue) continue;

		filterValues.set(key, Array.isArray(paramValue) ? paramValue : [paramValue]);
	}

	return Object.fromEntries(filterValues);
}

export function getCategories(
	searchParams: NextSearchParams,
	centraCategory: string | undefined,
) {
	const categories = searchParams.categories
		? Array.isArray(searchParams.categories)
			? searchParams.categories
			: [searchParams.categories]
		: centraCategory
			? [centraCategory]
			: undefined;

	return categories;
}

export function getSearchValue(searchParams: NextSearchParams, searchKey?: string) {
	if (!searchKey) return;
	const search = searchParams[searchKey];

	if (!search) return;
	if (Array.isArray(search)) return search.at(-1);
	if (typeof search === "string") return search;
}

export interface ServerFilterOptions {
	filterKeys?: string[];
	centraCategoryId?: string;
	searchKey?: string;
	categoryUri?: string;
}

type CustomOptions = {
	[key: string]: string | string[] | undefined;
};
export type ServerFilterReturn = {
	search?: string;
	categories?: string[];
	uri?: {
		uri: string;
		for: ["category"];
	};
	sortOrder?: FetchProductsOptions["sortOrder"] | undefined;
} & CustomOptions;

export function getServerFilters(
	searchParams: NextSearchParams,
	{
		centraCategoryId: centraCategory,
		filterKeys,
		searchKey,
		categoryUri,
	}: ServerFilterOptions,
) {
	const filterValues = getFiltersFromParams(searchParams, filterKeys);
	const search = getSearchValue(searchParams, searchKey);
	const categories = getCategories(searchParams, centraCategory);
	const sortOrder = getSortOrderFromParam(searchParams.sort);

	const uri = categoryUri
		? {
				uri: categoryUri,
				for: ["category" as const],
			}
		: undefined;

	const cache = !search && isEmpty(filterValues || {});

	return [
		{
			search,
			categories,
			uri,
			sortOrder: sortOrder
				? [
						{
							...sortOrder,
						},
					]
				: undefined,
			...filterValues,
		} as ServerFilterReturn,
		{ cache },
	] as const;
}
