import { useLoggedIn } from "@/lib/centra/selection/fetchers";
import { useCallback, useMemo } from "react";
import {
	useAddToCentraWishlist,
	useClearCentraWishlist,
	useRemoveFromCentraWishlist,
	useWishlistItemIds,
	useWishlistProducts,
} from "./useCentraWishlist";
import { useHydrateLocalWishlist, useLocalWishlist } from "./useLocalWishlist";

export type WishlistProps<T> = {
	fetcher: (ids: string[]) => Promise<T[]>;
};

export type WishlistReturn<T> = {
	products: T[];
	itemsIds: string[];
	add: (itemId?: string) => void;
	remove: (itemId?: string) => void;
	has: (itemId?: string) => boolean;
	clear: () => void;
	count: number;
	isEmpty: boolean;
	isLoading: boolean;
};

export const useWishlist = <T>({ fetcher }: WishlistProps<T>): WishlistReturn<T> => {
	useHydrateLocalWishlist();

	const { data: isLoggedIn, isLoading: authLoading } = useLoggedIn();
	const { items: itemsIds, isLoading: loadingItems } = useWishlistItemIds();

	const { data: products, isLoading: loadingProducts } = useWishlistProducts({
		fetcher,
	});

	const addToLocalWishlist = useLocalWishlist((state) => state.add);
	const addToCentraWishlist = useAddToCentraWishlist();

	const add = useCallback(
		(itemId?: string) => {
			isLoggedIn ? addToCentraWishlist.mutate(itemId) : addToLocalWishlist(itemId);
		},
		[isLoggedIn, addToLocalWishlist, addToCentraWishlist],
	);

	const removeFromLocalWishlist = useLocalWishlist((state) => state.remove);
	const removeFromCentraWishlist = useRemoveFromCentraWishlist();

	const remove = useCallback(
		(itemId?: string) => {
			isLoggedIn
				? removeFromCentraWishlist.mutate(itemId)
				: removeFromLocalWishlist(itemId);
		},
		[isLoggedIn, removeFromLocalWishlist, removeFromCentraWishlist],
	);

	const clearLocalWishlist = useLocalWishlist((state) => state.clear);
	const clearCentraWishlist = useClearCentraWishlist();

	const clear = useCallback(() => {
		isLoggedIn ? clearCentraWishlist.mutate() : clearLocalWishlist();
	}, [isLoggedIn, clearCentraWishlist, clearLocalWishlist]);

	const has = useCallback(
		(itemId?: string) => itemsIds.some((item) => item === itemId),
		[itemsIds],
	);

	const count = useMemo(() => itemsIds.length, [itemsIds]);

	const isEmpty = useMemo(() => itemsIds.length === 0, [itemsIds]);

	return {
		itemsIds,
		products: products || [],
		add,
		remove,
		has,
		clear,
		count,
		isEmpty,
		isLoading: authLoading || loadingProducts || loadingItems,
	};
};
