"use client";

import { Breadcrumbs } from "@/components/ui/Breadcrumbs";
import { useParams } from "next/navigation";
import { IGNORED_BREADCRUMB_PATHS } from "./config";

export const PathBreadcrumbs = () => {
	const params = useParams();
	const { locale, slug } = params;

	const segments =
		typeof slug === "string"
			? [slug]
			: (slug ?? []).filter((segment) => !IGNORED_BREADCRUMB_PATHS.includes(segment));

	if (segments.length === 0 || segments[0] === "home") return null;

	const breadcrumbs = segments.map((segment, i) => {
		const href = segments.slice(0, i + 1).join("/");

		return {
			title: segment.replaceAll("-", " "),
			href: `/${locale}/${href}`,
		};
	});

	return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
