import { classed, clsx } from "@frend-digital/ui";
import textStyles from "../Text/index.module.css";
import styles from "./index.module.css";

export const Tag = classed.div({
	base: clsx(styles.root!, textStyles.root!),
	variants: {
		variant: {
			blue: styles.blue!,
			white: styles.white!,
			red: styles.red!,
			transparent: styles.transparent!,
			beige: styles.beige!,
		},
		size: {
			mini: clsx(styles.mini!, textStyles.utility6!),
			xSmall: clsx(styles.xSmall!, textStyles.utility5!),
			small: clsx(styles.small!, textStyles.utility4!),
			large: clsx(styles.large!, textStyles.utility1!),
		},
		weight: {
			medium: styles.medium!,
			normal: styles.normal!,
		},
	},
	defaultVariants: {
		variant: "blue",
		size: "small",
	},
});
