import { env } from "@/env.mjs";

import { useLoggedIn } from "@/lib/centra/selection/fetchers";
import { useToken } from "@frend-digital/centra/client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ofetch } from "ofetch";
import {
	useClearLocalWishlist,
	useHydrateLocalWishlist,
	useLocalWishlistItems,
} from "./useLocalWishlist";
import { WISHLIST_KEY } from "./utils";

const getWishListKey = () => {
	const token = useToken();
	return [WISHLIST_KEY, token] as const;
};

export const centraFetch = ofetch.create({
	baseURL: env.NEXT_PUBLIC_CENTRA_CHECKOUT,
});

export interface CentraWishlistResponse {
	token: string;
	wishlist: {
		items: Array<{ wishlistItem: number; product: number }>;
		isDefault: boolean;
		wishlist: number;
	};
}

type WishListItemsProps<T> = {
	fetcher: (ids: string[]) => Promise<T[]>;
	limit?: number;
};

export const useWishlistProducts = <T>({ limit, fetcher }: WishListItemsProps<T>) => {
	useHydrateLocalWishlist();

	const { items: itemIds, isLoading: loadingItemIds } = useWishlistItemIds();

	return useQuery({
		queryKey: ["wishlist", { itemIds, limit }],
		queryFn: async () => {
			const limitedItems = limit ? itemIds.slice(0, limit) : itemIds;
			return await fetcher(limitedItems);
		},
		enabled: itemIds?.length > 0,
	});
};

export const useWishlistItemIds = () => {
	const token = useToken();
	const queryClient = useQueryClient();
	const { data: isLoggedIn, isLoading: authLoading } = useLoggedIn();

	const clearLocalWishlist = useClearLocalWishlist();
	const localWishlistItems = useLocalWishlistItems();

	const { data: centraItems, isLoading: centraWishlistLoading } =
		useCentraWishlistItems();

	useQuery({
		queryKey: [
			WISHLIST_KEY,
			{
				isLoggedIn,
				token,
				items: localWishlistItems,
			},
			"centra-hydrate",
		],
		queryFn: async () => {
			if (!isLoggedIn || !localWishlistItems) return null;

			const promises = localWishlistItems.map(async (itemId) => {
				const response = await centraFetch<CentraWishlistResponse>(
					`/customer/wishlists/0/items/${itemId}`,
					{
						method: "POST",
						headers: {
							"API-Token": `${token}`,
						},
					},
				);
				return response;
			});

			const responses = await Promise.allSettled(promises);
			clearLocalWishlist();
			queryClient.invalidateQueries({ queryKey: [WISHLIST_KEY, token] });

			return responses;
		},
		enabled: isLoggedIn && localWishlistItems?.length >= 1 && !!token,
	});

	if (!isLoggedIn) {
		return {
			items: localWishlistItems,
			isLoading: authLoading,
		};
	}

	return {
		items: centraItems?.items ? centraItems.items.map((i) => String(i.product)) : [],
		isLoading: centraWishlistLoading,
	};
};

export const useAddToCentraWishlist = () => {
	const wishlistKey = getWishListKey();
	const token = useToken();
	const queryClient = useQueryClient();
	const { data: isLoggedIn } = useLoggedIn();

	const mutation = useMutation({
		mutationKey: [WISHLIST_KEY, token, isLoggedIn],
		mutationFn: async (itemId?: string) => {
			if (!itemId || !token || !isLoggedIn) return;

			const response = await centraFetch(`/customer/wishlists/0/items/${itemId}`, {
				method: "POST",
				headers: {
					"API-Token": token,
				},
			});

			return response;
		},
		onMutate: (itemId) => {
			queryClient.cancelQueries({ queryKey: [WISHLIST_KEY] });

			const oldData =
				queryClient.getQueryData<CentraWishlistResponse["wishlist"]>(wishlistKey);

			if (oldData) {
				queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(wishlistKey, {
					...oldData,
					items: [
						...oldData.items,
						{
							wishlistItem: oldData.items.length + 1,
							product: Number(itemId),
						},
					],
				});
			}

			return { oldData };
		},
		onError: (_, __, ctx) => {
			ctx?.oldData &&
				queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(
					wishlistKey,
					ctx.oldData,
				);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [WISHLIST_KEY],
			});
		},
	});

	return mutation;
};

export const useRemoveFromCentraWishlist = () => {
	const wishlistKey = getWishListKey();
	const token = useToken();
	const queryClient = useQueryClient();
	const { data: isLoggedIn } = useLoggedIn();

	const mutation = useMutation({
		mutationKey: [WISHLIST_KEY, token, isLoggedIn],
		mutationFn: async (itemId?: string) => {
			if (!itemId || !token || !isLoggedIn) return;

			const response = await centraFetch(`/customer/wishlists/0/items/${itemId}`, {
				method: "DELETE",
				headers: {
					"API-Token": token,
				},
			});

			return response;
		},
		onMutate: (itemId) => {
			queryClient.cancelQueries({ queryKey: wishlistKey });

			const oldData =
				queryClient.getQueryData<CentraWishlistResponse["wishlist"]>(wishlistKey);

			if (oldData) {
				queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(wishlistKey, {
					...oldData,
					items: oldData.items.filter((i) => String(i.product) !== String(itemId)),
				});
			}

			return {
				oldData,
			};
		},
		onError: (_, __, ctx) => {
			ctx?.oldData &&
				queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(
					wishlistKey,
					ctx.oldData,
				);
		},

		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["wishlist"] });
		},
	});

	return mutation;
};

export const useCentraWishlistItems = () => {
	const token = useToken();
	const wishlistKey = getWishListKey();
	const { data: isLoggedIn } = useLoggedIn();

	const query = useQuery({
		queryKey: [...wishlistKey, isLoggedIn],
		queryFn: async ({ signal }) => {
			const response = await centraFetch<CentraWishlistResponse>(
				"/customer/wishlists/0",
				{
					headers: {
						"API-Token": `${token}`,
					},
					signal,
				},
			);

			return response.wishlist;
		},
		enabled: Boolean(!!isLoggedIn && !!token),
	});

	return query;
};

export const useClearCentraWishlist = () => {
	const wishlistKey = getWishListKey();
	const token = useToken();
	const queryClient = useQueryClient();
	const { data: isLoggedIn } = useLoggedIn();
	const { items } = useWishlistItemIds();

	const mutation = useMutation({
		mutationKey: [WISHLIST_KEY, token, isLoggedIn],
		mutationFn: async () => {
			if (!token || !items) return;

			const deletePromises = items.map((item) =>
				centraFetch(`/customer/wishlists/0/items/${item}`, {
					method: "DELETE",
					headers: {
						"API-Token": token,
					},
				}),
			);

			return Promise.all(deletePromises);
		},
		onMutate: () => {
			queryClient.cancelQueries({ queryKey: wishlistKey });

			const oldData =
				queryClient.getQueryData<CentraWishlistResponse["wishlist"]>(wishlistKey);

			if (oldData) {
				queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(wishlistKey, {
					...oldData,
					items: [],
				});
			}

			return { oldData };
		},
		onError: (_, __, ctx) => {
			if (ctx?.oldData) {
				queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(
					wishlistKey,
					ctx.oldData,
				);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["wishlist"] });
		},
	});

	return mutation;
};
