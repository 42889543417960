import { classed, clsx } from "@frend-digital/ui";
import styles from "./index.module.css";

export const Flex = classed("div", {
	base: styles.root!,
	variants: {
		direction: {
			row: styles.row!,
			column: styles.column!,
		},
		gap: {
			xs: styles["gap-xs"]!,
			sm: styles["gap-sm"]!,
			md: styles["gap-md"]!,
			lg: styles["gap-lg"]!,
			xl: styles["gap-xl"]!,
			"2xl": styles["gap-2xl"]!,
		},

		align: {
			start: styles.alignStart!,
			center: styles.alignCenter!,
			end: styles.alignEnd!,
			stretch: styles.alignStretch!,
		},
		justify: {
			start: styles.justifyStart!,
			center: styles.justifyCenter!,
			end: styles.justifyEnd!,
			between: styles.justifyBetween!,
			around: styles.justifyAround!,
			evenly: styles.justifyEvenly!,
		},

		center: {
			true: clsx(styles.justifyCenter, styles.alignCenter),
		},
	},
});
